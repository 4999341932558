import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { init } from './translations';


const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement!);

init(initReactI18next);

root.render(
  <React.StrictMode>
      {/*<LocalizationProvider>*/}
      {/*    <ThemeProvider theme={theme}>*/}
      {/*        <SnackbarProvider maxSnack={3}>*/}
      {/*            /!* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. *!/*/}
      {/*            <CssBaseline />*/}
                  <App />
      {/*        </SnackbarProvider>*/}
      {/*    </ThemeProvider>*/}
      {/*</LocalizationProvider>*/}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
