

interface IndexPageProps {

}


export default function IndexPage (props: IndexPageProps) {

    return (
        <>Test 123</>
    );
}
