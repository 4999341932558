import axios from 'axios';
import {ROUTES_CONFIG} from "../routes/routesConfig";

export const BASE_URL = "https://vending.api.cloud.biobringer.dev/admin";

const axiosClient = axios.create({
    baseURL: BASE_URL,
    withCredentials: true
});

axiosClient.interceptors.response.use(response => response, (error) => {
    if (error!== null && error.response !== null && error.response.status === 401) {
        window.location.assign(ROUTES_CONFIG.APP.routes.LOGIN.path);
    }

    return Promise.reject(error);
});

export function handleAxiosError(resource: string, error: any): Error {

    if (error !== null && error.response !== null && error.response.status === 401) {
        window.location.assign(ROUTES_CONFIG.APP.routes.LOGIN.path);
    }

    return new Error("axios-failed");
}

export default axiosClient;
