import Box from "@mui/material/Box";
import {styled} from '@mui/material/styles';
import {TopNavbar} from "../components/navigation/TopNavbar";
import {LeftDrawer} from "../components/navigation/LeftDrawer";
import {useBreakpoint} from "../hooks/useBreakpoint";
import {useState} from "react";
import {DRAWER_WIDTH} from "../style/theme";



interface DefaultLayoutProps {
    children: React.ReactNode;
}

const AppBarOffset = styled(Box)(({ theme }) => theme.mixins.toolbar);

export function DefaultInAppLayout (props: DefaultLayoutProps) {
    const {smDown} = useBreakpoint();
    const [open, setOpen] = useState<boolean>(true);

    const handleToggleDrawer = () => {
        setOpen(!open);
    };

    const handleOpenDrawer = () => {
        setOpen(true);
    };

    const handleCloseDrawer = () => {
        setOpen(false);
    };

    return (
        <>
            <TopNavbar
                toggleOpenDrawer={handleToggleDrawer}
            />
            <LeftDrawer
                open={open}
                handleClose={handleCloseDrawer}
                handleOpen={handleOpenDrawer}
                handleToggle={handleToggleDrawer}
            />
            <AppBarOffset/>
            <Box component={'main'} sx={{
                pl: open ? `${DRAWER_WIDTH}px` : 0,
                transition: (theme) => theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen
                }),
            }}>
                {props.children}
            </Box>
        </>
    );
}
