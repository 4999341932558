import {Box, Grid, Drawer, Toolbar, List} from "@mui/material";
import {useTranslation} from 'react-i18next';
import {MenuItem} from "./MenuItem";
import SettingsIcon from '@mui/icons-material/Settings';
import Refresh from '@mui/icons-material/Refresh';
import DashboardIcon from "@mui/icons-material/Dashboard";
import EuroIcon from '@mui/icons-material/Euro';
import {ROUTES_CONFIG} from "../../routes/routesConfig";
import {DRAWER_WIDTH} from "../../style/theme";

// const drawerWidth = 240;

interface LeftDrawerProps {
    open: boolean;
    handleClose: () => void;
    handleOpen: () => void;
    handleToggle: () => void;
}

export function LeftDrawer(props: LeftDrawerProps) {
    const { t } = useTranslation();

    return (
        <Drawer
            anchor="left"
            variant="persistent"
            open={props.open}
            onClose={props.handleClose}
            sx={{
                width: DRAWER_WIDTH,
                flexShrink: 0,
                overflowX: 'hidden',
                transition: (theme) => theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: props.open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen
                }),
                [`& .MuiDrawer-paper`]: {
                    width: DRAWER_WIDTH,
                    boxSizing: 'border-box'
                },
            }}
        >
            <Toolbar/>
            <Box height={'100%'}>
                <Grid container
                      justifyContent={'space-between'}
                      direction={'column'}
                      height={'100%'}
                >
                    <Grid item>
                        <Box sx={{ overflow: 'auto' }}>
                            <List>
                                <MenuItem
                                    title={t('components-navigation-dashboard') as string}
                                    open={props.open}
                                    route={ROUTES_CONFIG.APP.path}
                                >
                                    <DashboardIcon sx={{ color: 'primary.main' }} />
                                </MenuItem>
                                <MenuItem
                                    title={t('components-navigation-refill') as string}
                                    open={props.open}
                                    route={ROUTES_CONFIG.APP.routes.REFILL.path}
                                >
                                    <Refresh sx={{ color: 'primary.main' }} />
                                </MenuItem>
                                <MenuItem
                                    title={t('components-navigation-vends') as string}
                                    open={props.open}
                                    route={ROUTES_CONFIG.APP.routes.VENDS.path}
                                >
                                    <EuroIcon sx={{ color: 'primary.main' }} />
                                </MenuItem>
                            </List>
                        </Box>
                    </Grid>
                    <Grid item>
                        <MenuItem
                            title={t('components-navigation-settings') as string}
                            open={props.open}
                            route={ROUTES_CONFIG.APP.routes.SETTINGS.path}
                        >
                            <SettingsIcon sx={{ color: 'primary.main' }} />
                        </MenuItem>
                    </Grid>
                </Grid>
            </Box>
        </Drawer>
    );
}
