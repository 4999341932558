import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import {ReactElement} from "react";

interface MenuItemProps {
    title: string,
    route: string;
    open: boolean,
    children: ReactElement,
}


export function MenuItem (props: MenuItemProps) {

    return (
        <ListItem disablePadding>
            <ListItemButton
                href={props.route}
                sx={{
                    justifyContent: props.open ? 'initial' : 'center',
                }}
            >
                <ListItemIcon>
                    {props.children}
                </ListItemIcon>
                <ListItemText
                    primary={props.title}
                    sx={{ color: 'primary.main' }}
                />
            </ListItemButton>
        </ListItem>
    );
}
