import {
    Box,
    Grid,
    Container,
} from "@mui/material";
import {TitleCard} from "../../components/title/TitleCard";
import {useTranslation} from "react-i18next";


interface VendsPageProps {

}


export default function VendsPage (props: VendsPageProps) {
    const {t} = useTranslation();

    return (
        <Box sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: 'primary.lighter'
        }}>
            <Grid container mt={2} mb={2} sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                minHeight: "100vh"
            }}>
                <Grid item xs={12}>
                    <Container>
                        <TitleCard
                            title={t('pages-vends-title')}
                            subtitle={t('pages-vends-subtitle')}
                        />


                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
}
