import {Box, Card, CardContent, Typography} from "@mui/material";

interface TitleCardProps {
    title: string;
    subtitle: string;
}

export function TitleCard(props: TitleCardProps) {
    return (
        <Card sx={{mb: 2}}>
            <CardContent>
                <Box ml={1} mr={1}>
                    <Typography
                        variant="h5"
                        sx={{
                            typography: {sm: 'h5', xs: 'h6'},
                        }}
                        textAlign="left"
                        color={'primary.main'}
                    >
                        <b>
                            {props.title}
                        </b>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        textAlign="left"
                        color={'primary.main'}
                    >
                        {props.subtitle}
                    </Typography>
                </Box>
            </CardContent>
        </Card>

    )
}
