import { useState } from 'react';
import {AppBar, Grid, IconButton, Toolbar} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';


interface TopNavbarProps {
    toggleOpenDrawer: Function
}


export function TopNavbar (props: TopNavbarProps) {
    const handleToggleDrawer = () => {
        props.toggleOpenDrawer();
    };

    return (
        <AppBar
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
            position="fixed"
        >
            <Toolbar>
                <Grid container
                      justifyContent={'space-between'}
                >
                    <Grid item>
                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="open drawer"
                            color="inherit"
                            onClick={handleToggleDrawer}
                            sx={{
                                mr: 2,
                                color: 'white'
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
