import axiosClient, {handleAxiosError} from "./axiosClient";

export const AUTH_SUB_PATH = "/auth"

export const getUsernamePasswordLogin  = async (body: {
    username: string,
    password: string
}) => {
    try {
        const response = await axiosClient.post(`${AUTH_SUB_PATH}/login`, body)
        return response.data
    } catch (e) {
        throw handleAxiosError(`${AUTH_SUB_PATH}/token`, e);
    }
}

export const getLogout  = async () => {
    try {
        const response = await axiosClient.get(`${AUTH_SUB_PATH}/logout`)
        return response.data
    } catch (e) {
        throw handleAxiosError(`${AUTH_SUB_PATH}/token`, e);
    }
}
