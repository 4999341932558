import {lazy} from 'react';
import {path} from './utils/routesUtil';

import IndexPage from '../pages/IndexPage';
import LoginPage from '../pages/public/LoginPage';
import VendsPage from "../pages/inapp/VendsPage";
const RefillPage = lazy(() => import('../pages/inapp/RefillPage'));
const SettingsPage = lazy(() => import('../pages/inapp/SettingsPage'));



const PATH_ROOT = '/' as const;



// ======= PUBLIC =======
const PATH_LOGIN = path(PATH_ROOT, 'login');

// ======= IN APP =======
const PATH_REFILL = path(PATH_ROOT, 'refill');
const PATH_VENDS = path(PATH_ROOT, 'vends');
const PATH_SETTINGS = path(PATH_ROOT, 'settings');

export const ROUTES_CONFIG = {
  APP: {
    path: PATH_ROOT,
    component: IndexPage,
    routes: {
      LOGIN: {
        path: PATH_LOGIN,
        component: LoginPage,
      },
      REFILL: {
        path: PATH_REFILL,
        isSecure: true,
        component: RefillPage,
      },
      VENDS: {
        path: PATH_VENDS,
        isSecure: true,
        component: VendsPage,
      },
      SETTINGS: {
        path: PATH_SETTINGS,
        isSecure: true,
        component: SettingsPage,
      },
    },
  },
};

