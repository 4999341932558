import {createContext, ReactNode, useContext, useEffect, useMemo} from "react";
import {getUsernamePasswordLogin, getLogout} from "../api/auth";


export interface LoginData {
    username: string;
    password: string;
}

interface AuthContextType {
    login: (loginData: LoginData) => void;
    logout: () => void;
}

export const AuthContext = createContext<AuthContextType>(
    {} as AuthContextType
);


export function AuthProvider({children}: {
    children: ReactNode;
}): JSX.Element {



    const login = async (loginData: LoginData) => {
        await getUsernamePasswordLogin(loginData);
    };

    const logout = async () => {
        await getLogout()
    };


    const memoedValue = useMemo(
        () => ({
            login,
            logout,
        }),
        []
    );

    return (
        <AuthContext.Provider value={memoedValue}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
}
