import {useEffect, useState} from "react";
import {useNavigate, useSearchParams, useLocation} from "react-router-dom";
import {Box, Grid, Paper, TextField, Typography, Container, Button, Alert, AlertTitle, Divider, darken} from "@mui/material";
import {ReactComponent as BiobringerLogoSVG} from "../../assets/svg/logo_braun.svg";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {ROUTES_CONFIG} from "../../routes/routesConfig";
import {findRoutePerPath} from "../../routes/utils/routesUtil";
import {LoginData, useAuth} from "../../hooks/useAuth";
import GoogleIcon from "@mui/icons-material/Google";
import {BASE_URL} from "../../api/axiosClient";
import {AUTH_SUB_PATH} from "../../api/auth";

interface LoginPageProps {}

export default function LoginPage(props: LoginPageProps) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const {t} = useTranslation();
    const {login} = useAuth();
    const [loginError, setLoginError] = useState(false);
    const [googleLoginError, setGoogleLoginError] = useState(false);
    const {control, handleSubmit, formState, trigger, setError} = useForm<LoginData>({
        mode: "onSubmit"
    });
    const {errors, isSubmitting, touchedFields, submitCount} = formState;

    function getReturnPath () {
        const returnPath = searchParams.get("returnPath");
        let redirectPath: string = ROUTES_CONFIG.APP.routes.REFILL.path;
        if (returnPath !== null) {
            const resolvedRoute = findRoutePerPath(ROUTES_CONFIG, returnPath);
            if (resolvedRoute !== null) {
                redirectPath = resolvedRoute.path;
            }
        }
        return redirectPath;
    }

    const handleOnSubmit = async (loginData: LoginData) => {
        try {
            await login(loginData);
            const redirectPath = getReturnPath();
            navigate(redirectPath)
        } catch (e: any) {
            setLoginError(true);
            // setError('username', { type: 'custom', message: t(`pages-login-err-${e.message}`) });
            // setError('password', { type: 'custom', message: t(`pages-login-err-${e.message}`) });
        }
    }

    function getGoogleSuccessLoginReturnUrl () {
        const redirectPath = getReturnPath();
        return encodeURIComponent(`${window.location.protocol}//${window.location.host}${redirectPath}`);
    }

    function getGoogleErrorLoginReturnUrl () {
        return encodeURIComponent(`${window.location.protocol}//${window.location.host}/login?error=err-google-login`);
    }

    useEffect(() => {
        const error = searchParams.get("error");
        if (error !== null && error === 'err-google-login') {
            setGoogleLoginError(true);
        }
    }, []);


    function getGoogleOidcUrl () {
        return `${BASE_URL}${AUTH_SUB_PATH}/login/google?return_success_url=${getGoogleSuccessLoginReturnUrl()}&return_failure_url=${getGoogleErrorLoginReturnUrl()}`
    }

    // function navigateToReturnUrl () {
    //     const queryParams = queryString.parse(location.search);
    //     // const returnUrl = getStrict(queryParams, 'returnUrl', routes.INDEX.path);
    //     navigate(returnUrl);
    // }

    return (
        <Box sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: 'primary.lighter'
        }}>
            <Grid container justifyItems={"center"} sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh"
            }}>
                <Grid item xs={12} md={6} sx={{ml: 0, mr: 0}}>
                    <Container maxWidth="sm">
                        <Paper sx={{
                            pl: 4, pr: 4,
                            pt: 2, pb: 2,
                        }}>
                            <form onSubmit={handleSubmit(handleOnSubmit)}>
                                <Box sx={{
                                    pl: {xs: 2, sm: 6, md: 8}, pr: {xs: 2, sm: 6, md: 8}
                                }}>
                                    <BiobringerLogoSVG/>
                                </Box>
                                <Box>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            typography: {sm: 'h5', xs: 'h6'},
                                            pb: {xs: 4, sm: 6}
                                        }}
                                        pl={2} pr={2}
                                        pt={2}
                                        textAlign="center"
                                        color={'primary.main'}
                                    >
                                        <b>
                                            {t('pages-login-app-name')}
                                        </b>
                                    </Typography>
                                </Box>
                                {
                                    loginError &&
                                        <Box pt={2} pb={2}>
                                            <Alert severity="error">
                                                <AlertTitle>{t("global-error")}</AlertTitle>
                                                {t("pages-login-error-login")}
                                            </Alert>
                                        </Box>
                                }

                                <Controller
                                    name="username"
                                    control={control}
                                    rules={{
                                        required: t('pages-login-username-err-req')
                                    }}
                                    render={({field}) => {
                                        return (
                                            <Box pt={2} pb={2}>
                                                <TextField
                                                    {...field}
                                                    label={t('pages-login-username')}
                                                    inputProps={{
                                                        "aria-label": "username",
                                                        autoComplete: "username",
                                                    }}
                                                    fullWidth
                                                    autoFocus={true}
                                                    variant="outlined"
                                                    sx={{input: {color: 'primary.dark'}}}
                                                    error={!!errors.username && (touchedFields.username || submitCount > 0)}
                                                    helperText={
                                                        errors.username?.message &&
                                                        (touchedFields.username || submitCount > 0)
                                                            ? t(errors.username.message as any)
                                                            : ""
                                                    }
                                                />
                                            </Box>
                                        );
                                    }}
                                />

                                <Controller
                                    name="password"
                                    control={control}
                                    rules={{
                                        required: t('pages-login-password-err-req')
                                    }}
                                    render={({field}) => {
                                        return (
                                            <Box pt={2} pb={2}>
                                                <TextField
                                                    {...field}
                                                    label={t('pages-login-password')}
                                                    type='password'
                                                    inputProps={{
                                                        "aria-label": "Password",
                                                        autoComplete: "current-password",
                                                    }}

                                                    variant="outlined"
                                                    fullWidth
                                                    autoFocus={false}
                                                    error={!!errors.password && (touchedFields.password || submitCount > 0)}
                                                    helperText={
                                                        errors.password?.message &&
                                                        (touchedFields.password || submitCount > 0)
                                                            ? t(errors.password.message as any)
                                                            : ""
                                                    }
                                                />
                                            </Box>
                                        );
                                    }}
                                />
                                <Box pt={2} pb={2}>
                                    <Button
                                        variant="contained"
                                        size="large"
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {t('pages-login-btn-login')}
                                    </Button>
                                </Box>
                            </form>

                            <Box pt={2} pb={2}>
                                <Divider/>
                            </Box>

                            {
                                googleLoginError &&
                                <Box pt={2} pb={2}>
                                    <Alert severity="error">
                                        <AlertTitle>{t("global-error")}</AlertTitle>
                                        {t("pages-login-error-google-login")}
                                    </Alert>
                                </Box>
                            }

                            <Box pt={2} pb={2}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        backgroundColor: "#DB4437",
                                        '&:hover': {
                                            backgroundColor: darken("#E57368", 0.3)
                                        }
                                    }}
                                    fullWidth
                                    href={getGoogleOidcUrl()}
                                    startIcon={
                                        <GoogleIcon/>
                                    }
                                >
                                    {t('pages-login-btn-login-google')}
                                </Button>
                            </Box>
                        </Paper>
                    </Container>
                </Grid>

            </Grid>
        </Box>
    );
}
