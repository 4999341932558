import { RoutePath } from '../models/RoutePath';

export function path<T extends string, U extends string>(
  root: T,
  sublink: U
): `${T}${U}` {
  return `${root}${sublink}` as const;
}

export function getRoutesAsList(
  routes: Record<string, RoutePath>,
  arr?: RoutePath[]
) {
  const changedRoutes = typeof arr === 'undefined' ? [] : arr;

  Object.keys(routes).forEach((key) => {
    const routeObject = routes[key];
    if (typeof routeObject.routes !== 'undefined') {
      getRoutesAsList(routeObject.routes, changedRoutes);
    }
    changedRoutes.push(routeObject);
  });

  return changedRoutes;
}

export function findRoutePerPath(
    routes: Record<string, RoutePath>,
    pathToFind: string
) : RoutePath | null {
  const filteredRoutesList = getRoutesAsList(routes).filter(item => item.path === pathToFind);
  return filteredRoutesList.length === 1 ? filteredRoutesList[0] : null;
}
